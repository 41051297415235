<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveEducation"
            @btnCallback="saveCallback"
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :disabled="disabled"
            :editable="editable"
            label="교육명"
            name="educationName"
            v-model="educationInfo.educationName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="disabled ? null :[
              { name: 'search', click: true, callbackName: 'searchEduMaster', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeEduMaster', color: 'red' }
            ]"
            :required="true"
            :disabled="true"
            :editable="editable"
            label="교육과정"
            name="educationCourseName"
            @searchEduMaster="searchEduMaster"
            @removeEduMaster="removeEduMaster"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :disabled="true"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :disabled="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :disabled="disabled"
            :editable="editable"
            :required="true"
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- <div class="row"> -->
            <!-- <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6"> -->
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="교육시작일시"
                type="datetime"
                :minuteStep="10"
                name="educationDate"
                v-model="educationInfo.educationStartDate">
              </c-datepicker>
            <!-- </div> -->
            <!-- <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                type="time"
                :minuteStep="10"
                name="educationDate"
                v-model="educationInfo.educationStartTime">
              </c-datepicker>
            </div> -->
          <!-- </div> -->
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6"> -->
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                :start="educationInfo.educationStartDate"
                label="교육종료일시"
                type="datetime"
                :minuteStep="10"
                name="educationDate"
                v-model="educationInfo.educationEndDate">
              </c-datepicker>
            <!-- </div> -->
            <!-- <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                type="time"
                :minuteStep="10"
                name="educationDate"
                v-model="educationInfo.educationEndTime">
              </c-datepicker>
            </div> -->
          <!-- </div> -->
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="교육장소"
            name="educationLocation"
            v-model="educationInfo.educationLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :disabled="true"
            :editable="editable"
            type="time"
            :minuteStep="10"
            label="교육시간"
            name="educationTime"
            v-model="educationInfo.educationTime"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :disabled="true"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="교육주기"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-radio
            :disabled="true"
            :editable="editable"
            type="edit"
            :comboItems="flagItems"
            itemText="codeName"
            itemValue="code"
            label="법정교육 여부"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag">
          </c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :disabled="disabled"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_METHOD_CD"
            itemText="codeName"
            itemValue="code"
            name="educationMethodCd"
            label="교육방법"
            v-model="educationInfo.educationMethodCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :disabled="true"
            :editable="editable"
            placeholder="교육과정선택시 자동입력"
            :rows="1"
            label="학습목적"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-multi-select
            :disabled="true"
            :editable="editable"
            codeGroupCd="REGULATION_BILL_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="주요 대상자"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            suffix="원"
            type="number"
            :editable="editable"
            :disabled="disabled"
            label="예상 계획"
            name="estimatedEducationExpenses"
            v-model="educationInfo.estimatedEducationExpenses">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-moc
            :editable="editable"
            :disabled="disabled"
            :isSubmit="saveCall.saveCallData"
            :document.sync="educationInfo"
            :documentId="param.eduEducationId"
            documentTitle="educationName"
            mocRelatedTaskCd="RT00000010"
            label="MOC번호"
            name="sopMocId"
            v-model="educationInfo.sopMocId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant
            :required="true"
            :editable="editable"
            :disabled="disabled"
            type="edit"
            name="plantCd"
            v-model="educationInfo.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            :required="true"
            :disabled="disabled"
            :data="educationInfo"
            label="주관 부서"
            name="departmentDeptCd"
            v-model="educationInfo.departmentDeptCd">
          </c-dept>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="교육내용"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="educationInfo.eduSubjectList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        noDataLabel="교육내용이 없습니다."
        :editable="editable && !disabled"
        rowKey="subjectNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable && !disabled && educationInfo.eduSubjectList.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>
import { uid } from 'quasar'
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-plan-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
    tabParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduCourseId: '',
        documentStatusCd: '',
        eduAttendeeList: [],
      }),
    },
    saveCall: {
      type: Object,
      default: () => ({
        saveCallData: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        estimatedEducationExpenses: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
      },
      educationDate: [],
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      grid: {
        columns: [
          {
            name: 'subjectName',
            field: 'subjectName',
            label: '과목 및 세부내용',
            align: 'left',
            style: 'width:300px',
            type: 'text',
            sortable: false,
          },
          // {
          //   name: 'subjectDetailName',
          //   field: 'subjectDetailName',
          //   label: '세부내용',
          //   style: 'width:100px',
          //   align: 'left',
          //   type: 'text',
          //   sortable: false,
          // },
          {
            name: 'educationTime',
            field: 'educationTime',
            label: '교육시간',
            type: 'datetime',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            type: 'text',
            label: '강사명',
            style: 'width:300px',
            align: 'left',
            sortable: false
          },
        ],
        height: '300px'
      },
      title: '',

      selectDate: '',
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.edu.plan.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      completeUrl: '',
      updateMode: false,
    };
  },
  computed: {
    disabled() {
      return Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd !== 'ESC000001'
    }

  },
  /* eslint-disable no-unused-vars */
  watch: {
    'educationInfo'() {
      this.$emit('transInfo', this.educationInfo);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.url;
      this.updateUrl = transactionConfig.sop.edu.plan.update.url;
      this.completeUrl = transactionConfig.sop.edu.annual.complete.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;
          this.updateMode = true;
        },);
      }
    },
    saveEducation() {
      if (this.param.eduEducationId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.educationInfo.regUserId = this.$store.getters.user.userId
              this.educationInfo.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.eduEducationId = result.data;
      this.saveCall.saveCallData = uid();
      this.getDetail();
    },
    addItem() {
      this.educationInfo.eduSubjectList.splice(0, 0, {
        eduCourseId: this.eduCourseId,
        subjectNo: uuidv4(),
        subjectName: '', // 교육명
        subjectDetailName: '', // 세부내용
        educationTime: '', // 교육시간
        instructorName: '', // 강사명
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
        })
      }
    },
    searchEduMaster() {
      this.popupOptions.title = '교육과정 검색'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/educationCurriculumnPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, data.selectData[0].eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName, // 교육과정명
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd, // 교육구분
          // 교육과정 마스터 데이터
          this.educationInfo.eduCourseId = _result.data.eduCourseId, // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle, // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge, // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall, // 교육종류 소
          this.educationInfo.educationPurpose = _result.data.educationPurpose, // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag, // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience, // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws, // 관련법규
          this.educationInfo.educationTime = _result.data.educationTime // 교육시간
          this.educationInfo.estimatedEducationExpenses = _result.data.estimatedEducationExpenses // 교육시간
          this.educationInfo.eduSubjectList = _result.data.eduCourseSubjectList;
        },);
      }
    },
    linkClick() {
      this.popupOptions.title = '교육과정 상세'; // 교육과정 상세
      this.popupOptions.param = {};
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCurriculumDetail.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLinkPopup;
    },
    closeLinkPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    removeEduMaster() {
      this.educationInfo = {
        eduEducationId: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        budgetPlan: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        eduSubjectList: [],
      }
    },
  }
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>